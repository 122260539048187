import angular from 'angular';
import './pp/app';
const applistTemplate = require('./mgmt/applications/applist-sastamala.tpl.html');
const singleAppTemplate = require('./mgmt/applications/singleapp-sastamala-2016.tpl.html');
angular.module('tkr.custom')
    .decorator('customizationConfig', CustomizationConfigDecorator);
CustomizationConfigDecorator.$inject = ['$delegate'];
function CustomizationConfigDecorator($delegate) {
    const cc = $delegate;
    cc.prcListDetailTemplate = require('./mgmt/prc/prc-list.tpl.html');
    cc.getAppListTemplate = (appPeriod) => {
        return applistTemplate;
    };
    cc.getSingleAppTemplate = (appPeriod) => {
        return singleAppTemplate;
    };
    return cc;
}
